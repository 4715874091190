<template>
  <div class="wrap">
    <!-- <van-button type="primary" @click="getUserLocation">获取地址</van-button> -->
    <!-- <div ref="mapContainer"></div> -->
    <main class="home">
      <!-- <div class="topBox" :style="{ marginTop: '-' + $store.state.safetyTop + 'px',height: 'calc(5.34rem + ' + $store.state.safetyTop + 'px)', paddingTop: 'calc(1.75rem + ' + $store.state.safetyTop + 'px)'}"> -->
      <div class="topBox">
        <div
          class="place"
          @click="openCitySelection">
          {{ city }}
        </div>
        <div
          class="cutEditionBtn"
          @click="cutEditionFn"
          :style="{ top: 'calc(.46rem + ' + $store.state.safetyTop + 'px)' }"
        >
          切换身份
        </div>
        <div class="homeSeek" @click="$router.push({ path: '/seek' })">
          <input type="text" placeholder="请输入你想要的内容" />
          <div class="btn"></div>
          <!-- <router-link class="btn" :to="{path: '/seek', query: {'key': value}}"></router-link> -->
        </div>
      </div>
      <div class="content">
        <section class="btnList">
          <div
            class="li"
            @click="loginJump(0, '/financial')"
            v-if="!$store.state.iosAudit"
          >
            <div class="icon">
              <img src="~@/assets/images/home/homeUlIcon1.png" alt="" />
            </div>
            <div class="p">金融服务</div>
          </div>
          <div class="li" @click="loginJump(0, '/labor')">
            <div class="icon">
              <img src="~@/assets/images/home/homeUlIcon2.png" alt="" />
            </div>
            <div class="p">人力劳务</div>
          </div>
          <div class="li" @click="loginJump(0, '/buildingMaterials')">
            <div class="icon">
              <img src="~@/assets/images/home/homeUlIcon3.png" alt="" />
            </div>
            <div class="p">交易商城</div>
          </div>
          <div class="li" @click="loginJump(0, '/equipment')">
            <div class="icon">
              <img src="~@/assets/images/home/homeUlIcon4.png" alt="" />
            </div>
            <div class="p">租赁商城</div>
          </div>
          <div class="li" @click="loginJump(0, '/service')">
            <div class="icon">
              <img src="~@/assets/images/home/homeUlIcon5.png" alt="" />
            </div>
            <div class="p">企业服务</div>
          </div>
          <div class="li" @click="loginJump(0, '/digital')">
            <div class="icon">
              <img src="~@/assets/images/home/homeUlIcon6.png" alt="" />
            </div>
            <div class="p">数字服务</div>
          </div>
          <!-- <div class="li" @click="loginJump(2, `${escape('pages/index/page')}&appId=gh_1c62528a197b&pubAppId=wx34e8004a2b6c8f4b`)">
            <div class="icon"><img src="~@/assets/images/home/homeUlIcon7.png" alt=""></div>
            <div class="p">项目资源</div>
          </div> -->
         
          <div
            class="li"
            @click="
              loginJump(
                1,
                'bridge:/webview/open/pageInit?url=https://h5-hjt.jiansheku.com/pages/huaJianTong/index'
              )
            "
          >
            <div class="icon">
              <img src="~@/assets/images/home/homeUlIcon7.png" alt="" />
            </div>
            <div class="p">项目资源</div>
          </div>
          <div class="li" @click="loginJump(0, '/engineeringsev')">
            <div class="icon">
              <img src="~@/assets/images/home/homeUlIcon8.png" alt="" />
            </div>
            <div class="p">智能建造</div>
          </div>
          <div class="li" @click="loginJump(0, '/knowledge')">
            <div class="icon">
              <img src="~@/assets/images/home/homeUlIcon9.png" alt="" />
            </div>
            <div class="p">专业知识</div>
          </div>
          <div class="li" @click="loginJump(0, '/industrialbrain')">
            <div class="icon">
              <img src="~@/assets/images/home/homeUlIcon10.png" alt="" />
            </div>
            <div class="p">产业分析</div>
          </div>
        </section>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#f70000">
          <van-swipe-item>
            <img src="~@/assets/images/home/homeBan1.jpg" alt="" />
            <div class="txt">
              <h2>
                开启企业数字化<br />
                新空间
              </h2>
              <h3>
                Open up a new space for enterprise<br />
                digitalization
              </h3>
            </div>
          </van-swipe-item>

          <van-swipe-item class="it2">
            <!-- <div @click="skipOutsideChain('https://sci.aupup.com/')"> -->
            <div
              @click="
                toWeChat('&appId=gh_d1ac80085321&pubAppId=wx22ba5ecedbc2f3ce')
              "
            >
              <img src="~@/assets/images/home/homeBan2.jpg" alt="" />
              <div class="txt">
                <h2>
                  供应商<br />
                  核心大数据分析平台
                </h2>
                <h4>塑评价体系 引领供采合作</h4>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>

        <section class="projectColumn">
          <van-tabs
            class="border"
            color="#f70000"
            line-width="56"
            title-inactive-color="#2d322d"
            swipe-threshold="4"
            v-model:active="active"
          >
            <van-tab title="项目商机"></van-tab>
            <van-tab title="热门团购"></van-tab>
            <van-tab title="材料交易"></van-tab>
            <van-tab title="租赁需求"></van-tab>
            <van-tab title="劳务资源"></van-tab>
          </van-tabs>
          <div class="projectList">
            <div class="item sty1" v-if="active === 0">
              <div class="li">
                <div class="state" v-if="true">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAACTklEQVQ4jY2VTYiOURTHf3dGspFE+UiTLCxIYibJRxrlY4NTCCkj8sTCxGaEbKQIC7GQixELjSR3omREIoWyY+Or8TGEohBSHB3O+3rm9bzjvZvznnvv+d3/Oc+59w2qStEIKRNgETANaLAp4CVwG+hUiWcL4yqBIWULgd3AuMKT/o6nwDaVeKYqMKRsF7Dd3SfAYeAO8AKwjcOBKcCG3IEHVOLmf4AhZTuBHT6/VSXu6UteSNlG4KC7h1RiaxkYUtYMXPNFUYmd/0m3BJ0LXHZ3iUo8VwL2ACOBNpW4rxZYDroGOA58BAYHzq+bD1yyOqnEhhoh/YGVQJdK7Akpuw+MB1rqgFW+b2+NsH7eOu3AJp/e73axLc5055YHDAMWAB0q8XMFrN6/+iTgOXDEl664bTaFI9x543Y1cNRUhJQNqICZsslAt0FV4mNffud2oAHr3Sk1pDXqI6/J3ZxAU9bkDd2oEt/n1srNbCm/8i88FHitErtDykzFPWBCSNlV4IdBXFmTSvxQUdohbr+YwpvuTC8f96d2puYhMBuY47DGAhi+x8Z1A3a4s8XrVIJ+cqil/6wgzfxo89/JgBcACx5t3Z7fZVCVOBYYUw0WUrYCmAh8BU7WqUSrzzLgIvCgKEgl/qwCmwqcdne9Svxe9HwtBQapxGNV0ivts/Y64e4pldjye77i+bJHtKTGWsbu6A1/WG1Yz84A7P6atdGuEteWGQUKlwP2QIzqSyHw1p47lRh7xRf9Bfh9NRXzgFm5PrMPY4q7LF2V+K1XIPALXkzfrYh9I2wAAAAASUVORK5CYII="
                  />已核验
                </div>
                <div class="state state2" v-else>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAACTElEQVQ4jY2VTYiNYRTHf/eODeUrFkMa1iwsCGHSIAxiykeycMWKomxG6L+5RxEWYqHkI2OBRAYllETylYWanWHhc1AUGRa4Opz3ztOdl+vU7dxznuf5P+f8n3POW6hUKuSJmbUBS4HpQBNQAF4C94BOSWfzzvUDNLMlwC5gfO5NffIM2C7pzF8BzWwnsCPMp8Ah4D7wAvCNjcAUYENy4X5JW/oBmlkZUPi3Sdr9r/DMbBNwIMyDkjZXAc2sBbgRi22SOuukm4HOA66GuVzSuQzwFTAaaJe093/AEtB1wFHgEzC8UC6XFwBXnCdJTbGpATgRl6yW1BP+IcApYBCwWNKX8HcBE4DSAGBNXLYnudhLZCwwE7hsZvMBP3wemAN0A8Vk/z7gOLDMnc3hvJOtSvoOrAQeAZO87uKXgS2U9DkBvB66xQFHhfE25UbSG8DpuA3MAPwBHgNzJT2pofJ96MEO2BBGXst4mr2J/S3Ir5XqWQd8Hf9H1rzeMOAS4Pw56Q+BqZ6emTXWAI4I3VuMlIi0MjD3n0w4WxTAGacXzWxgAjg79E0/eDqMrcmGQkTvkbVKei7po5dKDAdqXrk99IVipOUvNi5blfTDOwaYLKk78fdEVTRnNRgyEfjqtZt1SiuwMXq4K4f0XDGzacDdWCtJ6sgbXyuAoZKO1AFbG8Xs0iGp9JurmvHl3P0M80H06K0YrC5es9493r+uXY5JWl8lPyfCVYAPiDF1Mn73h1YdTp25nwAz8x73KLxUZiV19iEivubpSvJC7xPgF4cj29ZNpXaWAAAAAElFTkSuQmCC"
                  />未核验
                </div>
                <div class="til">
                  <h4>秀山县2024年国家数字种植业创新数字创...</h4>
                  <div class="jk"><img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAASCAYAAABrXO8xAAABqElEQVQ4jX3Tz4tOYRQH8I/plWTUEJEFUaPZWDA1G4z1lK66/gSpkbLym0RJYSMLjQVbjfQsnrKx08wKK2QiYkUzEllhTK+e23Pnve/bjLO4dc/3fM8933O+d0W73dYVZXEJR7A1pz/jPq4K8Xdd2iGWxR5MYzU+4CkSuBdDuX6/EKc7xLIYxgu8xyEhvumZYiceIDUfFeJUTZzHDyFubBRfQAvXhDifc+8wiJUtZTGeC/ZlsB+vsS23OK4sdglxFgey5nN9OI2vQnybCyczaRPWYwMeVkiIX7L+U4m4PaUaisYqrSHOCfE7nle6OvEEa1v5tdUAjmFCWdzEH4zgbAPvqx8zOLyYDvEuLuMkzuOGEK/3TPQtEW9jQFkMNchX0uawSohnGpvenI1xqz7HTywIcZ3/RVl8whasqbWNVCOXxRwOCvFZjwF241He9nC6a9NyCZxK3fAxFy5k/YPZfqPdluvunrZ6FKlRile4hzuLDlqS2GnwEv1C3LEUXN1kmUg3/Lsc2NQ4gBMpl7GL2RjppnVMZM92OSb9Gel+daQv/uohPsYs/APWJJIST521mQAAAABJRU5ErkJggg=="
                  />监控</div>
                </div>
                <div class="label">
                  <div class="i">招标公告</div>
                  <div class="i">其他</div>
                  <div class="i">综合评估法</div>
                </div>
                <ul>
                  <li>发布时间: <span>2024-09-27</span></li>
                  <li>开标时间: <span>2024-10-25</span></li>
                  <li>合同估算: <span>500万元</span></li>
                  <li class="block">
                    招财单位: <span>秀山县佳沃农业发展有限公司</span>
                  </li>
                </ul>
              </div>
              <div class="li">
                <div class="state" v-if="true">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAACTklEQVQ4jY2VTYiOURTHf3dGspFE+UiTLCxIYibJRxrlY4NTCCkj8sTCxGaEbKQIC7GQixELjSR3omREIoWyY+Or8TGEohBSHB3O+3rm9bzjvZvznnvv+d3/Oc+59w2qStEIKRNgETANaLAp4CVwG+hUiWcL4yqBIWULgd3AuMKT/o6nwDaVeKYqMKRsF7Dd3SfAYeAO8AKwjcOBKcCG3IEHVOLmf4AhZTuBHT6/VSXu6UteSNlG4KC7h1RiaxkYUtYMXPNFUYmd/0m3BJ0LXHZ3iUo8VwL2ACOBNpW4rxZYDroGOA58BAYHzq+bD1yyOqnEhhoh/YGVQJdK7Akpuw+MB1rqgFW+b2+NsH7eOu3AJp/e73axLc5055YHDAMWAB0q8XMFrN6/+iTgOXDEl664bTaFI9x543Y1cNRUhJQNqICZsslAt0FV4mNffud2oAHr3Sk1pDXqI6/J3ZxAU9bkDd2oEt/n1srNbCm/8i88FHitErtDykzFPWBCSNlV4IdBXFmTSvxQUdohbr+YwpvuTC8f96d2puYhMBuY47DGAhi+x8Z1A3a4s8XrVIJ+cqil/6wgzfxo89/JgBcACx5t3Z7fZVCVOBYYUw0WUrYCmAh8BU7WqUSrzzLgIvCgKEgl/qwCmwqcdne9Svxe9HwtBQapxGNV0ivts/Y64e4pldjye77i+bJHtKTGWsbu6A1/WG1Yz84A7P6atdGuEteWGQUKlwP2QIzqSyHw1p47lRh7xRf9Bfh9NRXzgFm5PrMPY4q7LF2V+K1XIPALXkzfrYh9I2wAAAAASUVORK5CYII="
                  />已核验
                </div>
                <div class="state state2" v-else>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAACTElEQVQ4jY2VTYiNYRTHf/eODeUrFkMa1iwsCGHSIAxiykeycMWKomxG6L+5RxEWYqHkI2OBRAYllETylYWanWHhc1AUGRa4Opz3ztOdl+vU7dxznuf5P+f8n3POW6hUKuSJmbUBS4HpQBNQAF4C94BOSWfzzvUDNLMlwC5gfO5NffIM2C7pzF8BzWwnsCPMp8Ah4D7wAvCNjcAUYENy4X5JW/oBmlkZUPi3Sdr9r/DMbBNwIMyDkjZXAc2sBbgRi22SOuukm4HOA66GuVzSuQzwFTAaaJe093/AEtB1wFHgEzC8UC6XFwBXnCdJTbGpATgRl6yW1BP+IcApYBCwWNKX8HcBE4DSAGBNXLYnudhLZCwwE7hsZvMBP3wemAN0A8Vk/z7gOLDMnc3hvJOtSvoOrAQeAZO87uKXgS2U9DkBvB66xQFHhfE25UbSG8DpuA3MAPwBHgNzJT2pofJ96MEO2BBGXst4mr2J/S3Ir5XqWQd8Hf9H1rzeMOAS4Pw56Q+BqZ6emTXWAI4I3VuMlIi0MjD3n0w4WxTAGacXzWxgAjg79E0/eDqMrcmGQkTvkbVKei7po5dKDAdqXrk99IVipOUvNi5blfTDOwaYLKk78fdEVTRnNRgyEfjqtZt1SiuwMXq4K4f0XDGzacDdWCtJ6sgbXyuAoZKO1AFbG8Xs0iGp9JurmvHl3P0M80H06K0YrC5es9493r+uXY5JWl8lPyfCVYAPiDF1Mn73h1YdTp25nwAz8x73KLxUZiV19iEivubpSvJC7xPgF4cj29ZNpXaWAAAAAElFTkSuQmCC"
                  />未核验
                </div>
                <div class="til">
                  <h4>秀山县2024年国家数字种植业创新数字创...</h4>
                  <div class="jk"><img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAASCAYAAABrXO8xAAABqElEQVQ4jX3Tz4tOYRQH8I/plWTUEJEFUaPZWDA1G4z1lK66/gSpkbLym0RJYSMLjQVbjfQsnrKx08wKK2QiYkUzEllhTK+e23Pnve/bjLO4dc/3fM8933O+d0W73dYVZXEJR7A1pz/jPq4K8Xdd2iGWxR5MYzU+4CkSuBdDuX6/EKc7xLIYxgu8xyEhvumZYiceIDUfFeJUTZzHDyFubBRfQAvXhDifc+8wiJUtZTGeC/ZlsB+vsS23OK4sdglxFgey5nN9OI2vQnybCyczaRPWYwMeVkiIX7L+U4m4PaUaisYqrSHOCfE7nle6OvEEa1v5tdUAjmFCWdzEH4zgbAPvqx8zOLyYDvEuLuMkzuOGEK/3TPQtEW9jQFkMNchX0uawSohnGpvenI1xqz7HTywIcZ3/RVl8whasqbWNVCOXxRwOCvFZjwF241He9nC6a9NyCZxK3fAxFy5k/YPZfqPdluvunrZ6FKlRile4hzuLDlqS2GnwEv1C3LEUXN1kmUg3/Lsc2NQ4gBMpl7GL2RjppnVMZM92OSb9Gel+daQv/uohPsYs/APWJJIST521mQAAAABJRU5ErkJggg=="
                  />监控</div>
                </div>
                <div class="label">
                  <div class="i">招标公告</div>
                  <div class="i">其他</div>
                  <div class="i">综合评估法</div>
                </div>
                <ul>
                  <li>发布时间: <span>2024-09-27</span></li>
                  <li>开标时间: <span>2024-10-25</span></li>
                  <li>合同估算: <span>500万元</span></li>
                  <li class="block">
                    招财单位: <span>秀山县佳沃农业发展有限公司</span>
                  </li>
                </ul>
              </div>
              <div class="li">
                <div class="state" v-if="false">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAACTklEQVQ4jY2VTYiOURTHf3dGspFE+UiTLCxIYibJRxrlY4NTCCkj8sTCxGaEbKQIC7GQixELjSR3omREIoWyY+Or8TGEohBSHB3O+3rm9bzjvZvznnvv+d3/Oc+59w2qStEIKRNgETANaLAp4CVwG+hUiWcL4yqBIWULgd3AuMKT/o6nwDaVeKYqMKRsF7Dd3SfAYeAO8AKwjcOBKcCG3IEHVOLmf4AhZTuBHT6/VSXu6UteSNlG4KC7h1RiaxkYUtYMXPNFUYmd/0m3BJ0LXHZ3iUo8VwL2ACOBNpW4rxZYDroGOA58BAYHzq+bD1yyOqnEhhoh/YGVQJdK7Akpuw+MB1rqgFW+b2+NsH7eOu3AJp/e73axLc5055YHDAMWAB0q8XMFrN6/+iTgOXDEl664bTaFI9x543Y1cNRUhJQNqICZsslAt0FV4mNffud2oAHr3Sk1pDXqI6/J3ZxAU9bkDd2oEt/n1srNbCm/8i88FHitErtDykzFPWBCSNlV4IdBXFmTSvxQUdohbr+YwpvuTC8f96d2puYhMBuY47DGAhi+x8Z1A3a4s8XrVIJ+cqil/6wgzfxo89/JgBcACx5t3Z7fZVCVOBYYUw0WUrYCmAh8BU7WqUSrzzLgIvCgKEgl/qwCmwqcdne9Svxe9HwtBQapxGNV0ivts/Y64e4pldjye77i+bJHtKTGWsbu6A1/WG1Yz84A7P6atdGuEteWGQUKlwP2QIzqSyHw1p47lRh7xRf9Bfh9NRXzgFm5PrMPY4q7LF2V+K1XIPALXkzfrYh9I2wAAAAASUVORK5CYII="
                  />已核验
                </div>
                <div class="state state2" v-else>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAACTElEQVQ4jY2VTYiNYRTHf/eODeUrFkMa1iwsCGHSIAxiykeycMWKomxG6L+5RxEWYqHkI2OBRAYllETylYWanWHhc1AUGRa4Opz3ztOdl+vU7dxznuf5P+f8n3POW6hUKuSJmbUBS4HpQBNQAF4C94BOSWfzzvUDNLMlwC5gfO5NffIM2C7pzF8BzWwnsCPMp8Ah4D7wAvCNjcAUYENy4X5JW/oBmlkZUPi3Sdr9r/DMbBNwIMyDkjZXAc2sBbgRi22SOuukm4HOA66GuVzSuQzwFTAaaJe093/AEtB1wFHgEzC8UC6XFwBXnCdJTbGpATgRl6yW1BP+IcApYBCwWNKX8HcBE4DSAGBNXLYnudhLZCwwE7hsZvMBP3wemAN0A8Vk/z7gOLDMnc3hvJOtSvoOrAQeAZO87uKXgS2U9DkBvB66xQFHhfE25UbSG8DpuA3MAPwBHgNzJT2pofJ96MEO2BBGXst4mr2J/S3Ir5XqWQd8Hf9H1rzeMOAS4Pw56Q+BqZ6emTXWAI4I3VuMlIi0MjD3n0w4WxTAGacXzWxgAjg79E0/eDqMrcmGQkTvkbVKei7po5dKDAdqXrk99IVipOUvNi5blfTDOwaYLKk78fdEVTRnNRgyEfjqtZt1SiuwMXq4K4f0XDGzacDdWCtJ6sgbXyuAoZKO1AFbG8Xs0iGp9JurmvHl3P0M80H06K0YrC5es9493r+uXY5JWl8lPyfCVYAPiDF1Mn73h1YdTp25nwAz8x73KLxUZiV19iEivubpSvJC7xPgF4cj29ZNpXaWAAAAAElFTkSuQmCC"
                  />未核验
                </div>
                <div class="til">
                  <h4>秀山县2024年国家数字种植业创新数字创...</h4>
                  <div class="jk"><img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAASCAYAAABrXO8xAAABqElEQVQ4jX3Tz4tOYRQH8I/plWTUEJEFUaPZWDA1G4z1lK66/gSpkbLym0RJYSMLjQVbjfQsnrKx08wKK2QiYkUzEllhTK+e23Pnve/bjLO4dc/3fM8933O+d0W73dYVZXEJR7A1pz/jPq4K8Xdd2iGWxR5MYzU+4CkSuBdDuX6/EKc7xLIYxgu8xyEhvumZYiceIDUfFeJUTZzHDyFubBRfQAvXhDifc+8wiJUtZTGeC/ZlsB+vsS23OK4sdglxFgey5nN9OI2vQnybCyczaRPWYwMeVkiIX7L+U4m4PaUaisYqrSHOCfE7nle6OvEEa1v5tdUAjmFCWdzEH4zgbAPvqx8zOLyYDvEuLuMkzuOGEK/3TPQtEW9jQFkMNchX0uawSohnGpvenI1xqz7HTywIcZ3/RVl8whasqbWNVCOXxRwOCvFZjwF241He9nC6a9NyCZxK3fAxFy5k/YPZfqPdluvunrZ6FKlRile4hzuLDlqS2GnwEv1C3LEUXN1kmUg3/Lsc2NQ4gBMpl7GL2RjppnVMZM92OSb9Gel+daQv/uohPsYs/APWJJIST521mQAAAABJRU5ErkJggg=="
                  />监控</div>
                </div>
                <div class="label">
                  <div class="i">招标公告</div>
                  <div class="i">其他</div>
                  <div class="i">综合评估法</div>
                </div>
                <ul>
                  <li>发布时间: <span>2024-09-27</span></li>
                  <li>开标时间: <span>2024-10-25</span></li>
                  <li>合同估算: <span>500万元</span></li>
                  <li class="block">
                    招财单位: <span>秀山县佳沃农业发展有限公司</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="item sty2" v-if="active === 1">
              <div class="li">
                <div class="pic">
                  <img src="~@/assets/images/home/homeHotPic6.jpg" alt="" />
                </div>
                <div class="txt">
                  <h4>商品名称商品名称商品名称商品名称商品名称商品名称商品名称</h4>
                  <div class="price">
                    <div class="num"><em>¥</em>30000</div>
                    <div class="label">即将成团</div>
                  </div>
                </div>
              </div>
              <div class="li">
                <div class="pic">
                  <img src="~@/assets/images/home/homeHotPic6.jpg" alt="" />
                </div>
                <div class="txt">
                  <h4>商品名称商品名称商品名称商品名称商品名称商品名称商品名称</h4>
                  <div class="price">
                    <div class="num"><em>¥</em>30000</div>
                    <div class="label">即将成团</div>
                  </div>
                </div>
              </div>
              <div class="li">
                <div class="pic">
                  <img src="~@/assets/images/home/homeHotPic6.jpg" alt="" />
                </div>
                <div class="txt">
                  <h4>商品名称商品名称商品名称商品名称商品名称商品名称商品名称</h4>
                  <div class="price">
                    <div class="num"><em>¥</em>30000</div>
                    <div class="label">拼团中</div>
                  </div>
                </div>
              </div>
              <div class="li">
                <div class="pic">
                  <img src="~@/assets/images/home/homeHotPic6.jpg" alt="" />
                </div>
                <div class="txt">
                  <h4>商品名称商品名称商品名称商品名称商品名称商品名称商品名称</h4>
                  <div class="price">
                    <div class="num"><em>¥</em>30000</div>
                    <div class="label">拼团中</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item sty2" v-if="active === 2">
              <div class="li">
                <div class="pic">
                  <img src="~@/assets/images/home/homeHotPic6.jpg" alt="" />
                </div>
                <div class="txt">
                  <h4>商品名称商品名称商品名称商品名称商品名称商品名称商品名称</h4>
                  <div class="price">
                    <div class="num"><em>¥</em>30000</div>
                  </div>
                </div>
              </div>
              <div class="li">
                <div class="pic">
                  <img src="~@/assets/images/home/homeHotPic6.jpg" alt="" />
                </div>
                <div class="txt">
                  <h4>商品名称商品名称商品名称商品名称商品名称商品名称商品名称</h4>
                  <div class="price">
                    <div class="num"><em>¥</em>30000</div>
                  </div>
                </div>
              </div>
              <div class="li">
                <div class="pic">
                  <img src="~@/assets/images/home/homeHotPic6.jpg" alt="" />
                </div>
                <div class="txt">
                  <h4>商品名称商品名称商品名称商品名称商品名称商品名称商品名称</h4>
                  <div class="price">
                    <div class="num"><em>¥</em>30000</div>
                  </div>
                </div>
              </div>
              <div class="li">
                <div class="pic">
                  <img src="~@/assets/images/home/homeHotPic6.jpg" alt="" />
                </div>
                <div class="txt">
                  <h4>商品名称商品名称商品名称商品名称商品名称商品名称商品名称</h4>
                  <div class="price">
                    <div class="num"><em>¥</em>30000</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item sty2" v-if="active === 3">
              <div class="li">
                <div class="pic">
                  <img src="~@/assets/images/home/homeHotPic6.jpg" alt="" />
                </div>
                <div class="txt">
                  <h4>商品名称商品名称商品名称商品名称商品名称商品名称商品名称</h4>
                  <div class="price">
                    <div class="num">362个需求</div>
                    <div class="label">个性标签</div>
                  </div>
                </div>
              </div>
              <div class="li">
                <div class="pic">
                  <img src="~@/assets/images/home/homeHotPic6.jpg" alt="" />
                </div>
                <div class="txt">
                  <h4>商品名称商品名称商品名称商品名称商品名称商品名称商品名称</h4>
                  <div class="price">
                    <div class="num">362个需求</div>
                    <div class="label">个性标签</div>
                  </div>
                </div>
              </div>
              <div class="li">
                <div class="pic">
                  <img src="~@/assets/images/home/homeHotPic6.jpg" alt="" />
                </div>
                <div class="txt">
                  <h4>商品名称商品名称商品名称商品名称商品名称商品名称商品名称</h4>
                  <div class="price">
                    <div class="num">362个需求</div>
                    <div class="label">个性标签</div>
                  </div>
                </div>
              </div>
              <div class="li">
                <div class="pic">
                  <img src="~@/assets/images/home/homeHotPic6.jpg" alt="" />
                </div>
                <div class="txt">
                  <h4>商品名称商品名称商品名称商品名称商品名称商品名称商品名称</h4>
                  <div class="price">
                    <div class="num">362个需求</div>
                    <div class="label">个性标签</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item sty1" v-if="active === 4">
              <div class="li">
                <div class="til">
                  <h4>好太太国际中心项目(中建)铝模中心项...</h4>
                  <div class="zb">招标中</div>
                </div>
                <div class="label">
                  <div class="i">广东省广州市</div>
                  <div class="i">242652.00㎡</div>
                  <div class="i">1元</div>
                </div>
                <ul>
                  <li>发布时间: <span>平台认证、平台担保、未认证</span></li>
                </ul>
                <div class="time">2024-07-22  18:00:00</div>
              </div>
              <div class="li">
                <div class="til">
                  <h4>好太太国际中心项目(中建)铝模中心项...</h4>
                  <div class="zb">招标中</div>
                </div>
                <div class="label">
                  <div class="i">广东省广州市</div>
                  <div class="i">242652.00㎡</div>
                  <div class="i">1元</div>
                </div>
                <ul>
                  <li>发布时间: <span>平台认证、平台担保、未认证</span></li>
                </ul>
                <div class="time">2024-07-22  18:00:00</div>
              </div>
              <div class="li">
                <div class="til">
                  <h4>好太太国际中心项目(中建)铝模中心项...</h4>
                  <div class="zb">招标中</div>
                </div>
                <div class="label">
                  <div class="i">广东省广州市</div>
                  <div class="i">242652.00㎡</div>
                  <div class="i">1元</div>
                </div>
                <ul>
                  <li>发布时间: <span>平台认证、平台担保、未认证</span></li>
                </ul>
                <div class="time">2024-07-22  18:00:00</div>
              </div>
            </div>
          </div>
        </section>

        <section class="lump commodity ">
          <div class="til">
            <em>热销商品</em>
            <!-- <div class="more">我的偏好</div> -->
          </div>
          <Waterfall></Waterfall>
        </section>
      </div>
      <!-- 选择城市 -->
      <city-selection
        :showOverlay="isCitySelectionOpen"
        @getCity="setCity"
      ></city-selection>
    </main>
  </div>
</template>
<script setup>
import Swiper, { Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import CitySelection from "@/components/CitySelection.vue";
import Waterfall from "@/components/Waterfall.vue";
import { getNewsTypes, getNews } from "@/api/http";
import { skipOutsideChain } from "@/api/public";
import { showDialog } from "vant";
import { ref, onMounted, onUnmounted  } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
const router = useRouter();
const store = useStore();

// const items = ref([
//   { text: 'Item 1', height: 200 },
//   { text: 'Item 2', height: 100 },
//   // 更多项...
// ]);

// const options = {
//   columnWidth: 200,
//   gutter: 20,
//   fitWidth: true
// };

const value = ref("");
const active = ref(0);
const active2 = ref(0);
const actWid = ref(0);
const actLeft = ref(0);
const city = ref("南宁");
const isCitySelectionOpen = ref(0);
const newsTypeArr = ref([]);
const list = ref([]);
const rdhdList = ref([]);
const typeLoad = ref(false);
const listLoad = ref(false);
const setCity = (val) => (city.value = val[1].text);
const openCitySelection = () => {
  isCitySelectionOpen.value++;
};
// 切换到工人端
const cutEditionFn = () => {
  window.location.href = "bridge:/base/changeAppSystem/pageInit?type=1";
};

const swiperAct = ref(0);
const mapContainer = ref("mapContainer");
let map = null;
const showMap = ref(false);
const prestoreId = ref(-1);
const prestoreUrl = ref("");

// 获取新闻类型
const newsType = async () => {
  typeLoad.value = true;
  const res = await getNewsTypes();
  newsTypeArr.value = res.data.data;
  const num = newsTypeArr.value[0].typeId;
  typeLoad.value = false;
  newsList(num);
};
newsType();

// 根据类型获取新闻列表
const newsList = async (typeId) => {
  listLoad.value = true;
  const res = await getNews({
    pageIndex: 1,
    pageSize: 4,
    typeId,
  });
  list.value = res.data.data;
  listLoad.value = false;
};

// 新闻类型切换
const newsChange = (i) => {
  const num = newsTypeArr.value[i].typeId;
  newsList(num);
};

// 热点活动
const getRDHD = async () => {
  const { data: res } = await getNews({
    pageIndex: 1,
    pageSize: 4,
    typeId: 4551,
  });
  rdhdList.value = res.data;
  console.log("测试", res.data);
};
getRDHD();

// app跳转链接
function skip(url) {
  window.location.href = url;
}

// 获取地址
const loadTianDiTuScript = async () => {
  const script = document.createElement("script");
  script.src = `https://api.tianditu.gov.cn/api?v=4.0&tk=e9b7bd908527d7dc3286b2d2b81fa129&type=text/javascript`;
  script.async = true;

  script.onload = () => {
    initializeMap();
    showMap.value = true;
  };
  script.onerror = () => {
    console.error("加载天地图API失败");
  };
  document.head.appendChild(script);
};

const initializeMap = async () => {
  if (window.T) {
    map = new window.T.Map(mapContainer.value, {
      center: [116.404, 39.915], // 默认坐标，实际应使用用户位置
      zoom: 15,
      layer: window.T.MapType.NORMAL_MAP,
    });
    // 获取用户本地位置
    const position = await new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error("浏览器不支持地理定位"));
      } else {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      }
    }).catch((error) => {
      console.error("获取位置失败:", error);
      return null;
    });

    if (position) {
      const {
        coords: { latitude, longitude },
      } = position;
      const userLocation = [longitude, latitude];
      map.centerAndZoom(userLocation, 15); // 根据用户位置调整地图中心
      // 示例：在地图上添加标记
      const marker = new window.T.Marker(userLocation).addTo(map);
    }
  } else {
    console.error("天地图API未正确加载");
  }
};

// 业务按钮跳转
const loginJump = (num, url) => {
  if (num == 1) {
    window.location.href = url;
  } else if (num == 2) {
    window.location.href = `bridge:/native/openMiniApp/pageInit?url=${url}`;
  } else {
    router.push(url);
  }
  // prestoreId.value = num
  // prestoreUrl.value = url
  // window.location.href = "bridge:/base/getAppUserLogin/pageInit?callback=callbackLoginEvent";
};

const escape = (url) => {
  return encodeURIComponent(url);
};

function setLine() {
  let tabList = document.querySelectorAll(".tab");
  actWid.value = tabList[active2.value].clientWidth;
  actLeft.value = tabList[active2.value].offsetLeft;
}
function cutFn(num) {
  active2.value = num;
  setLine();
}
function toWeChat(url) {
  const appIdMatch = url.match(/appId=(.*?)(?=&|$)/);
  const pubAppIdMatch = url.match(/pubAppId=(.*?)(?=&|$)/);
  const cleanedUrl = url.replace(/&?(appId|pubAppId)=[^&]*/g, "");
  let link = encodeURIComponent(cleanedUrl);
  window.location.href = `bridge:/native/openMiniApp/pageInit?url=${link}&${appIdMatch[0]}&${pubAppIdMatch[0]}`;
}

// 敬请期待
function comingSoon() {
  showDialog({
    title: "即将开放，敬请期待",
  }).then(() => {
    // on close
  });
}
// 应用商城
let yysc = () => {
  showDialog({
    title: "如需了解，请移步电脑端访问",
  }).then(() => {
    // on close
  });
};
onMounted(function () {
  // 登录状态
  window.callbackLoginEvent = (isLogin) => {
    // isLogin 1 已登录 0 未登录
    if (isLogin == "1") {
      if (prestoreId.value == 1) {
        window.location.href = prestoreUrl.value;
      } else if (prestoreId.value == 0) {
        router.push(prestoreUrl.value);
      }
      prestoreId.value = -1;
      prestoreUrl.value = "";
    } else {
      prestoreId.value = -1;
      prestoreUrl.value = "";
      // *****  调起登录页面的协议
      window.location.href =
        "bridge:/base/openLoginView/pageInit?callback=callbackLoginEvent";
    }
  };

  // setLine();

  const swiper = new Swiper(".swiper-container", {
    slidesPerView: "auto",
    spaceBetween: 13,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    on: {
      slideChangeTransitionStart: function () {
        swiperAct.value = this.activeIndex;
      },
    },
  });

  // 获取位置
  loadTianDiTuScript();
});
</script>
<style scoped lang="scss">
@import "~@/assets/css/vanTabs.scss";
.commodity{
.item {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  padding: 10px;
  box-sizing: border-box;
}
  
}
.wrap {
  padding-top: 0;
}
.home {
  padding: 0;
  background: no-repeat;
}
.topBox {
  position: relative;
  padding: 1.8rem 0.4rem .4rem;
  background: url(~@/assets/images/home/homeTopBg.jpg) top center;
  background-size: cover;
  .place {
    position: absolute;
    left: 0.4rem;
    top: 1rem;
    height: 0.6rem;
    line-height: 0.6rem;
    font-size: 0.28rem;
    color: #fff;
    padding: 0 0.28rem 0 0.4rem;
    background: url(~@/assets/images/iconPlace.png) no-repeat left center /
        0.23rem,
      url(~@/assets/images/iconDown.png) no-repeat right center / 0.14rem;
  }
  .cutEditionBtn {
    position: absolute;
    right: 0.4rem;
    top: 1rem;
    padding-left: 0.3rem;
    font-size: 0.26rem;
    height: 0.6rem;
    line-height: 0.6rem;
    color: #fff;
    background: url(~@/assets/images/iconCut.png) no-repeat left center /
      0.22rem;
  }
  h2 {
    font-size: 0.56rem;
    line-height: 0.66rem;
    color: #0d5fb2;
    font-weight: 600;
    margin-bottom: 0.2rem;
  }
  p {
    font-size: 0.24rem;
    color: #7c8ea6;
    line-height: 0.4rem;
  }
}
.content {
  position: relative;
  z-index: 5;
  padding: 0.6rem 0.4rem 0;
  border-radius: 0.28rem 0.28rem 0 0;
  background: #fff;
  overflow: hidden;
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 6rem;
    content: "";
    display: block;
    background: #f8f8f8;
  }
}
.homeSeek {
  position: relative;
  border-radius: 0.28rem;
  overflow: hidden;
  border: 0.03rem solid #fff;
  box-shadow: 0 0 0.4rem rgba(206, 190, 189, 0.3);
  input {
    border-radius: 0.27rem;
    border: 0 none;
    width: 100%;
    height: 1rem;
    padding: 0.2rem 1rem 0.2rem 0.3rem;
    line-height: 0.6rem;
    font-size: 0.24rem;
    color: #666;
    background: #f4f4f4;
    box-shadow: inset 0 0 0.2rem rgba(185, 171, 171, 0.2);
  }
  .btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 1rem;
    height: 1rem;
    background: url(~@/assets/images/iconSeek.png) no-repeat center;
    background-size: 0.36rem;
  }
}
.btnList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.21rem;
  padding-bottom: 0.34rem;
  .li {
    position: relative;
    width: 1.38rem;
    height: 1.5rem;
    margin: 0 0.02rem 0.1rem;
    .p {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 0.24rem;
      color: #666;
      text-align: center;
      white-space: nowrap;
    }
  }
}
.my-swipe {
  overflow: hidden;
  border-radius: 0.28rem;
  margin-bottom: 0.4rem;
  .van-swipe-item {
    position: relative;
    .txt {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      padding: 1.2rem 0.5rem 0;
    }
    h2 {
      font-size: 0.36rem;
      line-height: 0.44rem;
      color: #ffbb00;
      margin-bottom: 0.16rem;
      letter-spacing: 0.1rem;
      font-weight: 600;
    }
    h3 {
      font-size: 0.22rem;
      color: #b0c1ca;
      line-height: 1;
      font-family: "Gilroy-Bold";
      text-transform: uppercase;
      font-weight: 500;
    }
  }
  .it2 {
    h2 {
      background: linear-gradient(to left bottom, #bbf1f9, #22e5a4);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 0.1rem;
    }
    h4 {
      font-size: 0.24rem;
      color: #a1c5c2;
      line-height: 0.4rem;
    }
  }
}
.lump {
  margin-left: -0.4rem;
  margin-right: -0.4rem;
  padding: 0.5rem 0.4rem 0;
  .til {
    position: relative;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.6rem;
    em {
      border-left: 0.06rem solid #f70000;
      padding-left: 0.16rem;
      font-size: 0.34rem;
      line-height: 1;
    }
  }
  .til2 {
    position: relative;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.4rem;
    em {
      font-size: 0.28rem;
      line-height: 0.38rem;
    }
  }
  .more {
    font-size: 0.22rem;
    color: #999;
    line-height: 0.6rem;
    padding-right: 0.18rem;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAQCAYAAADESFVDAAAAUElEQVQokYXSMQ4AIAgDwOJX+f8bdDAmFVvsSC6UgcjMiZ2AyaDx7FD84NnUQq6zkJGFFUmo0AMduuIQHx4KXUDd9ICKJGBkwUEtqHX6VQAsLewPSU/aZZkAAAAASUVORK5CYII=)
      no-repeat right center;
    background-size: 0.08rem;
  }
}
.border {
  :deep(.van-tabs__nav) {
    .van-tab {
      margin-left: 0.3rem;
    }
  }
}

.projectColumn {
  :deep(.van-tabs__nav) {
    .van-tab--active {
      color: #f70000;
      font-size: 0.34rem;
      font-weight: 500;
    }
    .van-tabs__line {
      width: 1.36rem !important;
    }
  }
  .sty1 {
    padding-bottom: .3rem;
    .li {
      position: relative;
      padding: 0.6rem 0.28rem 0.22rem;
      background: #f8f8f8;
      border: 1px solid #eee;
      border-radius: 0.2rem;
      overflow: hidden;
      margin-bottom: 0.3rem;
      .state {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        height: 0.46rem;
        width: 1.14rem;
        line-height: 0.46rem;
        font-size: 0.22rem;
        color: #0ea86a;
        justify-content: center;
        align-items: center;
        background: #cae9dc;
        border-radius: 0 0 0.2rem 0;
        img {
          margin-right: 0.08rem;
          width: 0.2rem;
        }
      }
      .state2{
        color: #828282;
        background: #e0e0e0;
      }
      .til {
        display: flex;
        justify-content: space-between;
        h4 {
          font-size: 0.26rem;
          color: #333;
          line-height: 0.4rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex: 1;
        }
        .jk {
          display: flex;
          height: 0.34rem;
          width: 0.74rem;
          line-height: 0.34rem;
          font-size: 0.2rem;
          color: #ff514d;
          align-items: center;
          justify-content: center;
          background: #f9e9e7;
          border-radius: 0.04rem;
          margin-left: 0.3rem;
          img {
            margin-right: 0.05rem;
            width: 0.14rem;
          }
        }
        .zb{
          height: 0.34rem;
          width: 0.74rem;
          line-height: 0.34rem;
          font-size: 0.2rem;
          color: #0ea86a;
          background: #e0f0ea;
          border-radius: 0.04rem;
          margin-left: 0.3rem;
        }
      }
      .label {
        margin-top: 0.1rem;
        display: flex;
        .i {
          line-height: 0.38rem;
          white-space: nowrap;
          padding: 0 0.08rem;
          font-size: 0.2rem;
          margin: 0 0.13rem 0.13rem 0;
          border-radius: 0.04rem;
          color: #007df8;
          border: 1px solid #95c7f8;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        padding-top: 0.14rem;
        margin-top: 0.12rem;
        border-top: 1px solid #e2e5eb;
      }
      li {
        margin: 0.16rem 0.3rem 0 0;
        font-size: 0.22rem;
        color: #999;
        line-height: 0.3rem;
        span {
          color: #333;
        }
      }
      .time{
        font-size: .23rem;
        color: #999;
        line-height: .3rem;
        margin-top: .18rem;
      }
    }
  }
  .sty2{
    padding-bottom: .34rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .li{
      width: 3.22rem;
      border-radius: .1rem;
      background: #f8f8f8;
      margin-bottom: .26rem;
      overflow: hidden;
      .pic{
        height: 2.94rem;
        background: no-repeat center;
        background-size: cover;
      }
      .txt{
        padding: .18rem .2rem;
        h4{
          font-size: .26rem;
          color: #333;
          line-height: .3rem;
          margin-bottom: .18rem;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* 设置显示的行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis; /* 在最后一行添加省略号 */
        }
        .price{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .num{
            font-size: .29rem;
            color: #e62a2b;
            font-family: "Gilroy-Bold", "微软雅黑";
            em{
              font-size: .24rem;
            }
          }
          .label{
            height: .3rem;
            line-height: .3rem;
            font-size: .2rem;
            color: #fb8741;
            background: #fee7d9;
            padding: 0 .08rem;
            border-radius: .04rem;
          }
        }
      }
    }
  }
}

.commodity{
  padding-bottom: 1rem;
  background: #f8f8f8;
}
</style>