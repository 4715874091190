<template>
  <div class="wrap">
    <nav-ber title="劳务工资贷" homeUrl="/financial"></nav-ber>
    <main>
      <div class="topBox">
        <div class="row">
          <h4>劳务工资贷</h4>
          <div class="num">已服务5000+客户数</div>
        </div>
        <div class="p">金融机构-鹏金所</div>
        <div class="label">
          <div class="i">月利率1%</div>
          <div class="i">可随时提前还款</div>
          <div class="i">低于市场平均融资利率</div>
        </div>
      </div>
      <div class="content">
        <section>
          <h3 class="cTil"><em>借款利率</em></h3>
          <div class="pBox">
            <!-- <p>年化12%，日利率等于年利率/365天，月利率等于年利率/12月。</p> -->
            <p>
              月息1%，借款期限最长为90天（可展期30天），借款期间由劳务公司、包工头偿还利息及手续费（具体以借贷金额及周期商定），到期归还本金，可随时选择提前还款，不收取提前还款违约金。
            </p>
          </div>
        </section>
        <section>
          <h3 class="cTil"><em>借款要求</em></h3>
          <div class="pBox">
            <!-- <h4>一、主体要求：</h4>
            <p>对应项目已录入项目实名制、开通桂建通工资卡并且已有应收、未收工资的农民工，农民工发起借款时由劳务公司、包工头对该笔借款进行担保。</p>
            <h4>二、借款要求：</h4>
            <p>个人单笔借款金额不超过5000元，借款期限为90天（可展期30天）借款期间产生利息由劳务公司、包工头进行支付。</p>
            <h4>三、还款方式：</h4>
            <p>对应项目总包付款至劳务公司由劳务公司进行还款，对应项目总包通过农民工工资监管账户支付工人工资至桂建通卡后进行划扣还款。</p> -->

            <h4>一、借款要求：</h4>
            <p>
              对应项目已录入项目实名制、项目工人已开通桂建通工资卡并且已有应收、未收工资。劳务企业提交办理意向后，依据企业工人历史工资确定借款额度。
            </p>
            <h4>二、借款方式：</h4>
            <p>
              企业工人线上发起借款申请，劳务企业、包工头对该笔借款进行担保并支付利息，资金将发放至工人桂建通工资卡中。
            </p>
            <h4>三、还款方式：</h4>
            <p>
              对应项目总包付款至劳务公司由劳务公司进行还款；对应项目总包通过农民工工资监管账户支付工人工资至桂建通卡后由平台进行划扣还款。
            </p>
          </div>
        </section>
        <!-- <section>
          <h3 class="cTil"><em>贷后管理</em></h3>
          <div class="pBox">
            <p>通过平台劳务管理系统上的项目产值、收款数据，核实项目是否正常运行，农民工产值及收款数据是否正常更新，如连续2个月未产生新的产值，该项目暂停融资。</p>
            <p>该项目借款到期后若民工未收到款项，给予30天的延期，如30天内未结清，该授信主体暂停合作，同时启动履约农民工支付保函。</p>
          </div>
        </section> -->
        <div
          @click="
            $router.push({
              path: '/embed',
              query: {
                link:
                  'https://gjjf.gxcic.net/azx-mobile/lwjr?type=28&insurerName=鹏金所' +
                  parameter,
              },
            })
          "
          class="cApplyBtn"
        >
          申请办理
        </div>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { skipOutsideChain } from "@/api/public";
import { ref, onMounted, inject } from "vue";

const parameter = ref("");
const isFromApp = ref(1);
// 获取URL中的查询字符串
const checkSource = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const source = urlParams.get("source");

  if (!source) {
    isFromApp.value = 1;
    parameter.value = "";
  } else if (source === "link") {
    // 短信入口
    isFromApp.value = 2;
    parameter.value = "&source=link";
  } else if (source === "gjt") {
    // 桂建通入口
    isFromApp.value = 3;
    parameter.value = "&source=gjt";
  }
};

// 使用inject来注入_hmt变量
const _hmt = inject("_hmt");
const setCustomVariables = () => {
  // 设置自定义变量
  if (isFromApp.value == 1) {
    _hmt.push(["_setCustomVar", 1, "Source", "App", 1]);
  } else if (isFromApp.value == 2) {
    _hmt.push(["_setCustomVar", 1, "Source", "Link", 1]);
  } else {
    _hmt.push(["_setCustomVar", 1, "Source", "Gjt", 1]);
  }
  // 发送页面视图
  _hmt.push(["_trackPageview"]);
};

onMounted(() => {
  checkSource();
  setCustomVariables();
});
</script>

<style scoped lang="scss">
main {
  padding: 0;
  background: url(~@/assets/images/financial/lwgzdTopBg.jpg) no-repeat top;
  background-size: 100%;
}
.topBox {
  height: 3.36rem;
  padding: 0.55rem 0.4rem 0;
  .row {
    position: relative;
    display: flex;
    justify-content: space-between;
    h4 {
      font-size: 0.42rem;
      color: #fff;
      line-height: 0.52rem;
      font-weight: 600;
    }
    .num {
      font-size: 0.22rem;
      color: #fff;
      line-height: 0.32rem;
    }
  }
  .p {
    font-size: 0.24rem;
    color: #fff;
    line-height: 0.34rem;
  }
  .label {
    margin-top: 0.7rem;
    display: flex;
    justify-content: space-between;
    .i {
      padding: 0 0.22rem;
      height: 0.56rem;
      line-height: 0.56rem;
      font-size: 0.2rem;
      color: #f70202;
      border-radius: 0.04rem;
      background: linear-gradient(to bottom, #fff8f3, #ffe6d1);
      box-shadow: 0 0.06rem 0.2rem rgba(51, 51, 51, 0.2);
    }
  }
}
.content {
  padding: 0.6rem 0.4rem;
  border-radius: 0.28rem 0.28rem 0 0;
  background: #fff;
  section {
    margin-bottom: 0.6rem;
  }
  .cTil {
    margin-bottom: 0.33rem;
  }
  .pic {
    overflow: hidden;
    border-radius: var(--border-radius-s);
    border: 0.03rem solid #c7c7c7;
    margin-top: 0.3rem;
  }
  .cTil {
    text-align: center;
    em:after {
      right: 0;
      margin: 0 auto;
    }
  }
  .pBox {
    h4 {
      font-size: 0.22rem;
      color: #333;
      line-height: 0.36rem;
    }
    p {
      margin-bottom: 0.36rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>

